class Tree {
    constructor(list){
        this.nodes = [];
        if(list.length === 0){
            return;   
        }
        const listCopy = [...list];
    
        // Fisher-Yatesシャッフルアルゴリズム
        for (let i = listCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [listCopy[i], listCopy[j]] = [listCopy[j], listCopy[i]]; // 要素を交換
        }
        this.root = this.initdfs(listCopy);
    }

    merge(t, l, r){
        if(t === 0){
            return l / (1 + l / r);
        } else {
            return l + r;
        }
    }

    initdfs(list){
        if(list.length === 1){
            const id = this.nodes.length;
            this.nodes.push(
                {
                    'type': -1,
                    'ratio': list[0]['width'] / list[0]['height'],
                    'url': list[0]['url'],
                    'datatype': list[0]['type'],
                    'dataid': list[0]['id']
                }
            );
            return id;
        }
        const md = Math.floor(list.length / 2);
        const l = this.initdfs(list.slice(0, md));
        const r = this.initdfs(list.slice(md));
        const t = Math.floor(Math.random() * 2);
        const ratio = this.merge(t, this.nodes[l]['ratio'], this.nodes[r]['ratio']);
        const id = this.nodes.length;
        this.nodes.push(
            {
                'type': t,
                'ratio': ratio,
                'l': l,
                'r': r,
            }
        );
        return id;
    }

    getpos(width, height){
        if(this.nodes.length === 0){
            return [];
        }
        const r = this.root;
        const ratio = width / height;
        if(ratio > this.nodes[r]['ratio']){
            const p = height;
            const left = (width - height * this.nodes[r]['ratio']) / 2;
            const top = 0;
            return this.getposdfs(this.root, left, top, p);
        } else {
            const p = width / this.nodes[r]['ratio'];
            const left = 0;
            const top = (height - width / this.nodes[r]['ratio']) / 2;
            return this.getposdfs(this.root, left, top, p);
        }
    }

    getposdfs(i, left, top, p){
        const cur = this.nodes[i];
        const l = cur['l'];
        const r = cur['r'];
        if(cur['type'] === -1){
            return [{
                'left': left,
                'top': top, 
                'width': p * cur['ratio'], 
                'height': p,
                'url': cur['url'],
                'datatype': cur['datatype'],
                'dataid': cur['dataid']
            }];
        } else if(cur['type'] === 0){
            const q = 1 / (1 + this.nodes[l]['ratio'] / this.nodes[r]['ratio']);
            const lres = this.getposdfs(l, left, top, p * q);
            const rres = this.getposdfs(r, left, top + p * q, p * (1 - q));
            return [...lres, ...rres];
        } else if(cur['type'] === 1){
            const lres = this.getposdfs(l, left, top, p);
            const rres = this.getposdfs(r, left + p * this.nodes[l]['ratio'], top, p);
            return [...lres, ...rres];
        }
    }

    score(width, height){
        const pos = this.getpos(width, height);
        let sum = 0;
        let minsize = 1e18;
        for(const x of pos){
            sum += x['width'] * x['height'];
            minsize = Math.min(minsize, x['width'] * x['height']);
        }
        return sum + minsize * pos.length * 3;
    }
};

export default Tree;
