import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Typography, Stack, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './main.css';
import Tree from './opt.js';


const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
}

const Card = ({children, onClick, left, top}) => {
    return (
        <div className='card' style={{left: left, top: top}}>
            {children}
            <div className='delete'>
                <div className='deleteicon'>
                    <IconButton aria-label="delete" onClick={onClick}>
                        <DeleteIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

const App = () => {
    const inputRef = useRef(null);
    const [imgs, setImgs] = useState([0, []]);
    const [windowsize, setWindowsize] = useState([window.innerWidth, window.innerHeight]);
    const [background, setBackground] = useState([0, 0, 0, 0]);

    const processfile = (file) => {
        const type = file.type.substring(0, 5);
        const url = URL.createObjectURL(file);
        if(type === 'image'){
            const image = new Image();
            image.addEventListener('load', () => {
                setImgs(([cnt, list]) => {
                    return [cnt + 1, [...list, {
                        'id': cnt,
                        'url': url,
                        'type': type,
                        'width': image.naturalWidth,
                        'height': image.naturalHeight
                    }]];
                });
                const canvas = document.createElement('canvas');
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);
                const data = ctx.getImageData(0, 0, image.naturalWidth, image.naturalHeight).data;
                var r = 0;
                var g = 0;
                var b = 0;
                var n = 0;
                for(var i = 0; i < image.naturalHeight; i++){
                    for(var j = 0; j < image.naturalWidth;){
                        r += data[i * image.naturalWidth * 4 + j * 4];
                        g += data[i * image.naturalWidth * 4 + j * 4 + 1];
                        b += data[i * image.naturalWidth * 4 + j * 4 + 2];
                        n += 1;
                        if(i === 0 || i === image.naturalHeight - 1){
                            j += 1;
                        } else {
                            j += image.naturalWidth - 1;
                        }
                    }
                }
                setBackground(background => {
                    r += background[0] * background[3];
                    g += background[1] * background[3];
                    b += background[2] * background[3];
                    n += background[3];
                    r = Math.floor(r / n);
                    g = Math.floor(g / n);
                    b = Math.floor(b / n)
                    return [r, g, b, n];
                });
            });
            image.src = url;
        } else if(type === 'video'){
            const video = document.createElement('video');
            video.addEventListener('loadedmetadata', () => {
                setImgs(([cnt, list]) => {
                    return [cnt + 1, [...list, {
                        'id': cnt,
                        'url': url,
                        'type': type,
                        'width': video.videoWidth,
                        'height': video.videoHeight
                    }]];
                });
            });
            video.src = url;
        }
    }

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        for (const item of e.dataTransfer.items){
            const entry = item.webkitGetAsEntry();
            entry.file(processfile);
        }
    }

    const handleChange = (e) => {
        for (const item of e.target.files){
            processfile(item);
        }

    }

    const width = windowsize[0];
    const height = windowsize[1];
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowsize([window.innerWidth, window.innerHeight]);
        })
    }, []);

    let tree = new Tree(imgs[1]);
    for(let i = 0; i < 1023; i++){
        let newtree = new Tree(imgs[1]);
        if(newtree.score(width, height) > tree.score(width, height)){
            tree = newtree;
        }
    }

    const pos = tree.getpos(width, height);

    const del = (id) => {
        const newlist = [];
        for(const x of imgs[1]){
            if(x['id'] !== id){
                newlist.push(x);
            }
        }
        setImgs([imgs[0], newlist]);
    }

    const content = [];
    for (const x of pos){
        if(x['datatype'] === 'image'){
            content.push(
                <Card onClick={() => del(x['dataid'])} left={x['left']} top={x['top']} key={x['dataid']}>
                    <img className='img' src={x['url']} width={x['width']} height={x['height']} alt={x['dataid']}/>
                </Card>
            );
        } else if(x['datatype'] === 'video'){
            content.push(
                <Card onClick={() => del(x['dataid'])} left={x['left']} top={x['top']} key={x['dataid']}>
                    <video controls loop muted autoPlay className='video' src={x['url']} width={x['width']} height={x['height']}alt={x['dataid']} onLoadedMetadata={(e) => {e.target.muted = false}}/>
                </Card>
            );
        }
    }

    if(content.length === 0){
        content.push(
            <Container maxWidth='sm' key='container'>
                <Typography component='h1' variant='h3' sx={{ mt: 5 }}>Tiling</Typography>
                <Typography component='h2'>画像や動画を並べて表示します。</Typography>
                <Stack display='flex' justifyContent='center' alignItems='center' sx={{ my: 5 }}>
                    <Button variant='contained' onClick={() => {inputRef.current.click()}}>ファイルを選択</Button>
                </Stack>
                <ul>
                    <li>ファイルをこのウィンドウにドラッグアンドドロップするか、ボタンからファイルを選択してください。</li>
                    <li>F11 キーで全画面表示ができます。再び F11 キーを押すと元に戻ります。</li>
                    <li>一覧表示状態からでもファイルをドラッグアンドドロップすることができます。</li>
                    <li>画像や動画の右上のボタンを押すと削除できます。</li>
                </ul>
                <input hidden multiple type='file' accept='image/*,video/*' onChange={handleChange} ref={inputRef}/>
            </Container>
        );
        document.body.style.backgroundColor = 'white';
    } else {
        document.body.style.backgroundColor = `rgba(${background[0]}, ${background[1]}, ${background[2]}, 1)`;
    }

    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver} className='main' id='main'>
            {content}
        </div>
    )    
};


export default App;
